@import "../../theme-metro/styles/variables";

@blue: @darker-blue;
@lineColor: #e8e7e7;

/**
 *
 * Hide / Show
 * ice elements on CXP
 *
 */
.tab-names {
    color: @gray;
    display: none;
    margin-top: 15px;
}
div.hide.bd-bdDrop, .bd-designMode-true .lp-bb-widget-login .tab-names {
    display: block !important;
}

/**
 *
 * Improve UI
 * 4 ice elemnts on CXP
 *
 */
.hide.bd-bdDrop {
    background: #f1f1f1;
    margin: 0 0 50px 0;
    padding: 10px;

    h3 {
        margin: 0;
    }
    .cke_editable {
        background: #fff;
        border: 1px solid #ccc;
        padding: 2px 5px;

        &:hover {
            background: #ffffcc;
        }
    }
}

.pos-relative {
    position:relative
}

/**
 *
 * Widget Login UI
 *
 */
.lp-bb-widget-login {
    background: #fff;
    margin: 0 auto 50px;
    padding: 20px;
    width: 700px;
    overflow: auto;

    // numeric keypad hack fix to mask number imput field values
    // and show numeric keypad on mobile
    .sec-number {
        input:not([type]),
        input[type="number"] {
         -webkit-text-security: disc !important;
       }
    }

    .control-disabled {
      opacity: 0.5;
    }

    .login-info-links {
      width: 100%;
    }
    h2 {
        color: @brand-primary;
        font-family: @titleFont;
        font-size: 22px;
        font-weight: 300;
        margin: 0;
    }

    .biomatric-section{
        padding:15px;
        background: #f9ced1
    }

    .biomatric-section p{
        padding-top: 10px;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }

    .biomatricDisabled{
        margin-bottom:165px
    }

    .new-customer .links-login li {
      padding: 0 15%;
     }

    .steps-login {
        margin: 20px 0 22px;

        li {
            background: @gray;
            border: 1px solid @gray;
            border-radius: 25px;
            color: #fff;
            display: inline-block;
            height: 25px;
            line-height: 22px;
            position: relative;
            text-align: center;
            width: 25px;
        }
        span {
            transition: 1s all;
            background: #fff;
            background-image: linear-gradient(to left, white, @gray);
            border-bottom: 1px solid @gray;
            border-top: 1px solid @gray;
            display: inline-block;
            height: 4px;
            left: -5px;
            position: relative;
            top: -2px;
            width: 45px;
            z-index: 2;
        }
        .step-two {
            background: #fff;
            color: @gray;
            left: -10px;
        }
        .active {
            background: @gray;
            background-image: linear-gradient(to left, @gray, @gray);
            color: #fff;
        }
    }
    .ct-login {
        overflow: auto;
    }
    .business-login {
        float: left;
        width: 325px;

        .welcome-message-mobile {
          h3 {
            margin-top: 5px;
            margin-bottom: 3px;
          }
          p {
            color: @light-gray;
          }
        }
    }
    label {
        cursor: pointer;
        display: block;
        font-weight: lighter;
    }
    .ct-number, .ct-password, .ct-security {
        position: relative;
    }
    .customer-number,
    .customer-password {
        width: 325px;
        font-size: 20px;
        font-weight: 300;
        letter-spacing: 1px;
        padding-top: 9px;
        height: 50px;
        padding: 9px 6px 0px 6px;
    }
    .customer-password {
        line-height: 30px;
    }
    .ct-number {
        .into-input {
            font-size: 15px;
            left: 2px;
            top: 0px;
        }
        input:focus ~ .into-input, input.filled ~ .into-input, input:not([value=""]) ~ .into-input {
            left: -1px;
            top: -1px;
        }
    }
    .valid-number {
        color: green;
        position: absolute;
        right: -30px;
        top: 19px;
    }
    .input-error {
        border-color: red;
        color: #000;
    }
    .msg-error {
        color: red;
    }
    .ct-remember {
        border-bottom: 1px solid @lineColor;
        border-top: 1px solid @lineColor;
        margin: 25px 0 20px;
        padding: 20px 0;

        span {
            font-size: 13px;
            font-weight: normal;
            color: @black;
            margin-bottom: 5px;
        }
        span {
          display: block;
        }
        span.info-text {
            color: @gray;
            padding: 0;
            display: block;
        }

        .remember-me-checkbox {
          display: inline-block;
          width: 50px;
          top: -10px;
          -webkit-transform: scale(0.7,0.7);
          -moz-transform:    scale(0.7,0.7);
          -ms-transform:     scale(0.7,0.7);
          -o-transform:      scale(0.7,0.7);
          transform:         scale(0.7,0.7);
        }
    }
    .customer-remember {
        float: right;
    }

    .links-login {
        margin: 25px 0 0;

        li {
            display: inline-block;
            width: 60px;

            &:last-child {
                float: right;
            }
        }
        .middle-link {
            margin: 0 18%;
        }
        .label-link {
            display: block;
            font-size: 10px;
            line-height: 12px;
            margin: 0 auto;
            width: 50px;
        }
        a {
            transition: 1s all;
            color: @blue;
            display: block;
            text-align: center;

            &:hover {
                opacity: .7;
                text-decoration: none;
            }
        }
    }
    // #step 2
    .mb-label {
        color: @gray;
        display: block;
        font-weight: normal;
    }
    .partial-number {
        border-bottom: 1px solid @lineColor;
        margin-bottom: 20px;
        padding-bottom: 14px;

        b {
            font-size: 13px;
        }
        i {
            font-size: 12px;
            font-style: normal;
            font-weight: bold;
            letter-spacing: 6px;
            position: relative;
            top: 2px;
        }
        span {
            font-family: @titleFont;
            font-size: 20px;
            letter-spacing: 2px;
        }
    }
    .customer-password {
        letter-spacing: 2px;
    }
    .into-input {
        transition: 0.2s ease all;
        color: @gray;
        font-size: 20px;
        font-style: italic;
        font-weight: 200;
        left: 10px;
        padding: 4px 6px 0px 6px;
        pointer-events: none;
        position: absolute;
        top: 13px;
    }
    input:focus ~ .into-input, input.filled ~ .into-input {
        font-size: 11px;
        left: 6px;
        top: 2px;
    }
    .ct-password {
        .ct-icon {
            right: -146px;
            top: 16px;
        }
        .into-input {
            padding: 0;
            top: 11px;
        }
        input:focus ~ .into-input, input.filled ~ .into-input {
            left: 5px
        }
        .fa {
            display: inline-block;
            padding: 5px;
        }
        .valid-number {
            right: -35px;
            top: 16px;
        }
    }
    .ct-icon {
        color: @blue;
        position: absolute;
    }
    .ct-security {
        border-top: 1px solid @lineColor;
        margin-top: 20px;
        padding-top: 17px;

        ul {
            margin: 0;
        }
        .ct-icon {
            color: @blue;
            position: absolute;
            right: 68px;
            top: 45px;
        }
        .fa {
            display: inline-block;
            padding: 5px;
        }
        .valid-number {
            right: -103px;
            top: 0px;
        }
    }
    .sec-number {
        color: @gray;
        display: inline-block;
        margin-top: 7px;
        position: relative;
        text-align: center;
        width: 36px;

        input {
            line-height: 0px;
        }
        input ~ .into-input {
          left: 2px;
          top: 3px;
          font-size: 14px;
        }
        input.filled ~ .into-input,
        input:focus ~ .into-input {
            padding: 0;
            left: 3px;
            top: 0;
            font-size: 10px;
        }
        input[disabled] {
            cursor: not-allowed;
        }
    }
    .position {
        left: 12px;
        top: 5px;
    }
    .no-number {
        font-size: 15px;
        font-weight: bold;
        position: relative;
        top: 6px;
    }
    .input-code {
        background-color: transparent;
        border: 1px solid @gray;
        border-radius: 5px;
        height: 35px;
        position: relative;
        text-align: center;
        width: 35px;
        z-index: 2;
    }
    .ordinal {
        font-size: 9px;
    }
    .no-number, .input-code {
        display: inline-block;
        padding: 0 6px;
    }

    // new costumer
    .new-customer {
        background-color: #eee;
        border: 1px solid #afaaad;
        border-radius: 5px;
        float: right;
        min-height: 435px;
        padding: 20px;
        width: 300px;

        h3 {
            font-family: @titleFont;
            font-size: 16px;
            margin: 0;
        }
        p {
            border-bottom: 1px solid #ccc;
            font-size: 14px;
            line-height: 22px;
            margin: 20px 0 15px;
            padding-bottom: 15px;
        }
        li {
            margin-bottom: 8px;
        }
        a {
            transition: 1s all;
            color: @blue;
            text-decoration: underline;

            &:hover {
                opacity: .7;
                text-decoration: none;
            }
        }
    }

    .login-hardtoken {
       
        .box{
            background-color: #dcdcdc;
            min-height: 200px;
            padding-top: 5px;
            padding-left: 15px;
            padding-bottom: 5px;
        }
        p {
            border-bottom: none;
            font-size: 13px;
            line-height: 22px;
            margin: 20px 0 15px;
            padding-bottom: 15px;
        }
        
        a {
            transition: 1s all;
            color: @blue;
            text-decoration: underline;

            &:hover {
                opacity: .7;
                text-decoration: none;
            }
        }        
    }

    .box-calc {
        position: absolute;
        top: 58px;
        right: -10px;
    }
    .box-calc img, .soft-calc img {
        height: 140px;
    }

    .soft-calc{
        position: absolute;
        top: 130px;
        right: -10px;
    }
    .box-calc img, .soft-calc img {
        margin-right:-20px
    }
    @media (max-width:958px){
        .soft-calc{
            top:20px;
        }
        .box-calc {
           top:-45px;
        }
        .box-calc img, .soft-calc img {
            top: 100px;
            right: 20px !important;
            position: absolute;
        }
    }
    
   
    /**
     * Tooltip
     */
    .ct-tooltip {
        position: relative;
        text-align: center;
        width: 250px;
        -webkit-transform: translateZ(0); /* webkit flicker fix */
        -webkit-font-smoothing: antialiased; /* webkit text rendering fix */
    }
    .ct-tooltip .tooltip {
        background: #6988d0;
        bottom: 100%;
        border-radius: 5px;
        color: #fff;
        display: block;
        margin-bottom: 15px;
        opacity: 0;
        padding: 10px;
        pointer-events: none;
        position: absolute;
        width: 100%;
        -webkit-transform: translateY(10px);
        -moz-transform: translateY(10px);
        -ms-transform: translateY(10px);
        -o-transform: translateY(10px);
        transform: translateY(10px);
        -webkit-transition: all .25s ease-out;
        -moz-transition: all .25s ease-out;
        -ms-transition: all .25s ease-out;
        -o-transition: all .25s ease-out;
        transition: all .25s ease-out;
        -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
        -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
        -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
        -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    }

    /* This bridges the gap so you can mouse into the tooltip without it disappearing */
    .ct-tooltip .tooltip:before {
        bottom: -20px;
        content: " ";
        display: block;
        height: 20px;
        left: 0;
        position: absolute;
        width: 100%;
    }

    /* CSS Triangles - see Trevor's post */
    .ct-tooltip .tooltip:after {
        border-left: solid transparent 10px;
        border-right: solid transparent 10px;
        border-top: solid #6988d0 10px;
        bottom: -10px;
        content: " ";
        height: 0;
        left: 50%;
        margin-left: -10px;
        position: absolute;
        width: 0;
    }
    .fa:hover + .tooltip {
        opacity: .9;
        pointer-events: auto;
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
        transform: translateY(0px);
    }

    /* IE can just show/hide with no transition */
    .lte8 .ct-tooltip .tooltip {
        display: none;
    }
    .lte8 .ct-tooltip:hover .tooltip {
        display: block;
    }
    // Spinner
    .spinner {
        display: inline-block;
        opacity: 0;
        width: 0;
        -webkit-transition: opacity 0.25s, width 0.25s;
        -moz-transition: opacity 0.25s, width 0.25s;
        -o-transition: opacity 0.25s, width 0.25s;
        transition: opacity 0.25s, width 0.25s;
    }
    .has-spinner.active {
        cursor: progress;
    }
    .has-spinner.active .spinner {
        opacity: 1;
        width: 50px;
    }
    .has-spinner.btn-mini.active .spinner {
        width: 10px;
    }
    .has-spinner.btn-small.active .spinner {
        width: 13px;
    }
    .has-spinner.btn.active .spinner {
        width: 16px;
    }
    .has-spinner.btn-large.active .spinner {
        width: 19px;
    }
    .temp {
        background-color: #f2dede;
        border-radius: 5px;
        margin-bottom: 20px;
        padding: 10px 20px;
    }
}

/**
 *
 * Widget Login UI Responsiveness
 *
 */
@media (max-width: @screen-sm-max) {
    .bp-manageableArea {
        width: auto !important
    }
    .lp-bb-widget-login {
        background: transparent;
        margin: 0;
        width: 100%;

        input:active, input:focus {
            background-color: @white;
            border: 1px solid @light-gray;
            border-radius: 3px;
            color: @black;
        }

        .filled, .customer-number, .customer-password {
            background: @white;
            border-color: @light-gray;
            color: @light-gray;
        }

        .customer-number, .customer-password {
            height: 50px;
            width: 100%;
        }

        .ct-number {
            .into-input {
                top: 20px;
                left: 13px;
                color: @light-gray;
            }
        }

        .ct-remember {
            border: none;
            color: #fff;
            margin: 0;
        }


        .business-login, .new-customer {
            float: none;
            width: 100%;
            padding: 10px;
            background: @white;
        }

        .new-customer {
            border: none;
            border-radius: 3px;
            min-height: auto;
            background-color: @gray-light;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

        .msg-error {
            color: #fff;
        }

        // Step 2
        .ct-password {
            .into-input {
              top: 12px;
              left: 13px;
              color: @light-gray;
            }
        }

        .ct-security {
            border: none;
            margin: 0 0 10px;
        }

        .mb-label {
            color: #fff;
        }

        .sec-number {
            color: @light-gray;
            width: 40px;

            @media (max-width: 400px) {
                width: 37px;
        }

            .input-code {
                height: 40px;
                width: 40px;
                @media (max-width: 400px) {
                width: 30px;
            }
            }

            input.filled~.into-input {
                color: #fff;
                z-index: 2;
            }
        }
    }
}

// Media queries to show and hide stuff for different screen size
@media (max-width: 768px) {
  .valid-number {
    top: 49px !important;
    right: 90px !important;
  }

  .lp-bb-widget-login {
    h2 {
      font-size: 15px;
      font-weight: 600;
      padding: 10px 4px;
      color: @black;
      margin: 0;
      background-color: @white;
    }


    .customer-number {
      height: 50px;
      background: @white;
      border-color: @light-gray;
      color: @light-gray;
    }

    input:focus {
      background: @white;
      border-color: @light-gray;
    }

    .ct-remember {
      b {
        display: block;
      }
    }
    .ct-password 
    {
        .ct-icon {
            right: -118px;
            top: unset;
            z-index: 99;
        }
    }

    .ct-security {
        border-top: 1px solid @lineColor;
        margin-top: 20px;
        padding-top: 17px;

        ul {
            margin: 0;
        }
        .ct-icon {
            color: @blue;
            position: absolute;
            right: 92px;
            top: 66px;
        }
    }
    
    .new-customer {
      background-color: @gray-light;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .ct-number {

      .into-input {
        top: 11px;
        left: 5px;
        color: @light-gray;
      }
    }
    .soft-token .l-width {
        max-width: 300px;
        font-size: 15px;
    }
    #registrationForm {
        padding: 10px;
    }
  }

}

@media (max-width: 991px) {
  .valid-number {
    top: 28px !important;
    right: 8px !important;
  }

  .ct-security .valid-number {
    top: 2px !important;
    right: -70px !important;
  }

  .lp-promo-content {
      display: none;
  }
  .welcome-message-mobile {
      display: block;
  }
}

@media (max-width: 370px) {
  .new-customer .links-login li {
    padding: 0 !important;
    margin: 0 4%;
   }
   .valid-number {
     right: 10px !important;
     top: 28px !important;
   }
   .ct-security .valid-number {
    top: 49px !important;
    right: 146px !important;
  }
  .ct-tooltip {
    display: none;
  }
}

/* user bind device modal */
.m-t-5{
    margin-top:5px !important;
}
.m-t-15{
    margin-top:15px;
}
.text-black{
    color:black;
}
.m-r-lg {
    margin-right:30px;
}
.m-r-sm {
    margin-right:10px;
}
.m-t-10 { margin-top:10px}
.m-t-0{margin-top:0px !important}
.m-b-0{margin-bottom:0px  !important}
.p-t-0{padding-top:0px  !important}
.p-b-0{padding-bottom:0px  !important}
.bold {font-weight: bold}

.list-description{list-style-position:outside;margin-left: 30px;}
.list-description li {position: relative; font-size: 13px; margin-bottom:15px!important;}
.list-description .list {color:#2a36b4}
.list-description .list-number { color:#fff}
.number-list {position:absolute; left:-30px;} 
.list-description p {margin:0px !important; padding:4px 0px 0px !important}

.notification-section { display: table;width:100%;padding: 5px 0px;}
.tr { display: table-row; }
.td { display: table-cell; margin: 5px;}
.imgage-icon {width: 20px; vertical-align: top; margin-top: 5px; margin-right: 10px}
.notification-container { background:#F5F8FB; padding:10px;}

.autowidth{ width:100% !important;}
.form-box { margin-top: 25px;  border: 1px solid #cccccc; padding: 15px;}

.modal-message-container{
    padding-bottom: 30px;
    border-bottom: 1px solid#D9D9D9;
    margin-bottom: 30px;
    margin-top:25px;
}

.header-text {
    max-width:100% !important
}


.lp-bb-widget-login ins{
    text-decoration: underline;
    cursor: pointer;
    color:#2a36b4
}

.lp-bb-widget-login ins:hover{
    text-decoration: none;
}
.soft-token .l-width {
    max-width: 400px;
}
.lp-bb-widget-login{
    overflow-x: hidden;
}

#registrationForm h3 {
    color: rgb(42, 54, 180);
    font-size: 23px;
    font-weight: 500;
    height: 27px;
    letter-spacing: -0.54px;
    line-height: 26.5px;
}
#registrationForm .appstore-img {
    height: 69px;
    width: 211px;
    margin: 0 10px;
    cursor: pointer;
}

#registrationForm .authenticator-app-img {
    width: 72px;
}
@media (min-width: 769px) {   
    #registrationModel .modal-dialog {
        margin-left: -16%;
        max-width: 499px;
    } 
}
.new-customer .bold,big {
    color: #2a36b4 !important;
    font-size: 16px !important;
    font-weight: 500;
    letter-spacing: -0.38px;
    line-height: 26.5px;
    font-family: Cabin,Helvetica,Arial;
}
.lp-bb-widget-login .new-customer p {
    color: rgb(82, 77, 72);
    font-size: 14px;
    padding-right: 10px;
    letter-spacing: 0px;
    line-height: 21px;
}
.new-customer .bullet {
    padding-left: 28px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.new-customer .step1desc {
    padding-top: 14px;
}

.new-customer .bullet li {
    list-style: disc;
    margin-bottom: 4px !important;
}

.step1desc2 {
    margin-top: 8px !important;
}

.new-customer .step1desc {
    padding-top: 14px;
}
.step1desc2 {
    margin-top: 8px !important;
}
.new-customer .bullet {
    padding-left: 28px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.new-customer .bullet2 {
    padding-left: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.new-customer .bullet li{
    list-style: disc;
    margin-bottom: 4px !important;
}
.new-customer .bullet2 li{
    list-style: disc;
    margin-bottom: 4px !important;
}

/* user bind device modal */
